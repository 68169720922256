const Navitems = [
  {
    href: '#home',
    content: `Home`,
  },
  {
    href: '#about',
    content: `About`,
  },
  {
    href: '#projects',
    content: `Projects`,
  },
  {
    href: '#contact',
    content: `Contact`,
  },
];

export default Navitems;
